<template>
  <v-row align="center" justify="center" class="mt-10">
    <v-col cols="12" class="header mb-2 pl-2 py-4">
      <span style="font-size: 24px; font-weight: 400">{{ $t("QR CODE") }}</span>
    </v-col>
    <v-card outlined width="100vw" class="pa-4">
      <v-row>
        <v-col cols="4"
          ><v-text-field label="search" solo dense></v-text-field
        ></v-col>
        <v-spacer />
        <v-col cols="4" class="text-right">
          <v-btn dark color="#de5c8e" @click="gotonew()">เพิ่ม QR CODE</v-btn>
        </v-col>
        <!-- <v-col cols="4" class="text-right">
          <v-btn dark color="#de5c8e" @click="opendialog()">เพิ่มข้อมูล</v-btn>
        </v-col> -->
      </v-row>

      <v-col cols="12">
        <v-data-table v-if="EngLang" :headers="headersEN" :items="items">
          <template v-slot:[`item.name`]="{ item }">
            <span>{{ item.name_of_user }}</span>
          </template>
          <template v-slot:[`item.qrcode`]="{ item }">
            <qr-code
              :text="stringqrcode + '/View-Doc8?id=' + item.id"
              size="56"
              color="#000"
              bg-color="#fff"
              error-level="H"
              class="ma-1"
            >
            </qr-code>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ convertDate(item.createdAt) }} </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :color="
                item.status == 'Waiting for verification'
                  ? 'orange'
                  : item.status == 'Request verified'
                  ? 'blue'
                  : item.status == 'Approved'
                  ? 'green'
                  : item.status == 'Pending'
                  ? 'orange'
                  : 'red'
              "
              dark
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
            <v-icon @click="DeleteItem(item.id)">mdi-delete</v-icon>
          </template>
        </v-data-table>
        <!-- แยกภาษา -->
        <v-data-table v-if="!EngLang" :headers="headersTH" :items="items">
          <template v-slot:[`item.qrcode`]="{ item }">
            <qr-code
              :text="stringqrcode + '/View-Doc8?id=' + item.id"
              size="56"
              color="#000"
              bg-color="#fff"
              error-level="H"
              class="ma-1"
            >
            </qr-code>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ convertDate(item.createdAt) }} </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :color="
                item.status == 'Waiting for verification'
                  ? 'orange'
                  : item.status == 'Request verified'
                  ? 'blue'
                  : item.status == 'Approved'
                  ? 'green'
                  : item.status == 'Pending'
                  ? 'orange'
                  : 'red'
              "
              dark
            >
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  v-if="item.status !== 'SAVE DRAFT'"
                  class="mr-1"
                  @click="goToView(item.id)"
                  >mdi-eye</v-icon
                >
              </template>
              <span>View</span>
            </v-tooltip>
            <v-icon @click="goToEdit(item.id)">mdi-pencil</v-icon>
            <v-icon @click="DeleteItem(item.id)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-card>
    <Dialog
      :dialog="dialog"
      :isEdit="isEdit"
      :form="form"
      @closeDialog="closeDialog()"
    />
  </v-row>
</template>

<script>
import { Decode } from "@/services";
import moment from "moment";
import Dialog from "@/components/Dialog/DialogQR";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      dialog: false,
      isEdit: false,
      form: {
        name_th: "",
        name_en: "",
      },
      userData: [],
      count: 0,
      EngLang: true,
      nationalityItems: [],

      headersTH: [
        { text: "ลำดับ", value: "count", align: "center" },
        {
          text: "Document Type",
          value: "document.document_type",
          align: "center",
        },
        { text: "full name", value: "fullname", align: "center" },
        { text: "วันที่เพิ่ม", value: "createdAt", align: "center" },
        { text: "QR Code", value: "qrcode", align: "center" },
        // { text: "สถานะ", value: "status",width:300 },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      headersEN: [
        { text: "No.", value: "count", align: "center" },
        {
          text: "Document Type",
          value: "document.document_type",
          align: "center",
        },
        { text: "full name", value: "fullname", align: "center" },
        { text: "Created At", value: "createdAt", align: "center" },
        { text: "QR Code", value: "qrcode", align: "center" },
        // { text: "Status", value: "status",width:300 },
        { text: "Action", value: "action", align: "center" },
      ],
      items: [],
      dataUrl: null,
      stringqrcode: process.env.VUE_APP_FRONTEND,
    };
  },
  created() {
    const lang = localStorage.getItem("lang");
    if (lang == "en") {
      this.EngLang = true;
      this.itemChangeLang = "name_en";
    } else if (lang == "th") {
      this.EngLang = false;
      this.itemChangeLang = "name_th";
    }
    this.getAll();
    // this.getdocuments();
  },
  methods: {
    async getAll() {
      const auth = {
        headers: {
          Authorization: `document_qr_code ${this.userData.token}`,
        },
      };
      // const data = {
      //   ...this.form,
      // };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/document_qr_code`
      );
      console.log("response", response.data.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    // async getdocuments() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/documents`
    //   );
    //   console.log("response documents", response.data.data);
    //   this.itemsdocuments = response.data.data;
    // },
    opendialog() {
      this.dialog = true;
    },
    gotonew() {
      this.$router.push("/Doc8");
    },
    closeDialog() {
      this.dialog = false;
      this.isEdit = false;
      this.form = {};
      this.getAll();
    },
    convertDate(val) {
      return moment(val).format("DD/MM/yyyy");
    },
    goToEdit(id) {
      console.log("item", id);
      this.$router.push(`/Edit-Doc8?id=${id}`);
    },
    goToView(id) {
      console.log("item", id);
      // this.$router.push(`/View-Doc8?id=${id}`);
      window.open(`/View-Doc8?id=${id}`);
    },
    async DeleteItem(id) {
      this.$swal({
        text: this.EngLang
          ? "Do you want to delete ?"
          : "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.EngLang ? "Cancel" : "ยกเลิก",
        confirmButtonText: this.EngLang ? "Yes" : "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.userData = JSON.parse(
            Decode.decode(localStorage.getItem("userCUdata"))
          );
          const auth = {
            headers: {
              Authorization: `document_qr_code ${this.userData.token}`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/document_qr_code/${id}`,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAll();
        }
      });
    },
  },
};
</script>

<style scoped>
.header {
  background-color: #de5c8e;
  padding: 10px;

  color: white;
  border-radius: 5px;
}
</style>